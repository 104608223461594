import axios from 'axios'

const host = window.location.host
const host_split = host.split('.')
const domain = host_split.length <= 2 ? host : (host_split[host_split.length - 2] + '.' + host_split[host_split.length - 1])

const service = axios.create({
  baseURL: window.location.protocol + '//api.' + domain,
  timeout: 15000
})

service.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/json'
  return config
}, error => Promise.reject(error))

service.interceptors.response.use(response => {
  return response.data
}, error => {
  return Promise.reject(error)
})

export default service
